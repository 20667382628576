import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Form } from "../../components/Form/Form";
import { Loading } from "../../components/Loading/Loading";
import axios from "axios";

export const Blogs = () => {
  const formRef = useRef(null);
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // States for blogs, loading, and error
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch blogs from /getallblogs API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://nat-backend.vercel.app/getallblogs"
        );
        if (response.data && Array.isArray(response.data)) {
          setBlogs(response.data); // Set blogs only if it's an array
        } else {
          throw new Error("Invalid response format"); // Handle invalid response format
        }
        setLoading(false);
      } catch (error) {
        setError(error.message || "Error fetching blogs");
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <p className="text-center text-red-500">{error}</p>
      </div>
    );
  }

  if (!Array.isArray(blogs) || blogs.length === 0) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <p className="text-center">No blogs available.</p>
      </div>
    );
  }

  return (
    <div className="bg-themebgwhite">
      <Header scrollToForm={scrollToForm} />
      <br />
      <br />
      <br />
      <div className="w-full bg-[#f9f9f9] py-[10px]">
        <div className="max-w-[1240px] mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 ss:grid-cols-1 gap-8 px-4 text-black">
            {blogs.map((blog) => (
              <Link key={blog._id} to={`/blogcontent/${blog.slug}`}>
                <div className="bg-white rounded-xl overflow-hidden drop-shadow-md md:h-auto flex flex-col justify-between">
                  {/* Smaller Cover Image */}
                  <img
                    className="h-40 w-full object-cover" // Changed h-56 to h-40
                    src={blog.coverImg} // Use Base64 directly with image prefix
                    alt="Blog Cover"
                  />
                  <div className="p-6 flex-1 flex flex-col">
                    {/* Responsive and Smaller text sizes */}
                    <h3 className="font-bold text-xl md:text-2xl my-1 font-Poppins text-[#132043]">
                      {blog.blogTitle}
                    </h3>
                    <p className="text-base md:text-l text-gray-600 font-Poppins flex-grow">
                      {blog.blogDesc}
                    </p>
                    {/* Displaying the category */}
                    <div className="mt-4">
                      <span className="text-[#e07a5a] font-bold text-sm md:text-base">
                        Category:{" "}
                      </span>
                      <span className="text-[#4e5683] text-sm md:text-base">
                        {blog.category}
                      </span>
                    </div>

                    {/* Author section */}
                    <div className="mt-6 flex items-center">
                      <img
                        src={blog.authorImg} // Use Base64 directly with image prefix
                        alt="Author"
                        className="w-8 h-8 rounded-full object-cover mr-4" // Smaller author image
                      />
                      <div>
                        <p className="text-[#132043] font-bold text-sm md:text-base">
                          {blog.authorName}
                        </p>
                        <p className="text-[#4e5683] text-xs md:text-sm">
                          {blog.authorDesc}
                        </p>
                      </div>
                    </div>

                    {/* Link to the full blog */}
                    <div className="mt-4 text-right">
                      <Link
                        to={`/blogcontent/${blog.slug}`} // Changed to blog.slug
                        className="text-[#132043] hover:text-[#e07a5a] font-bold text-sm md:text-base"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Form formRef={formRef} />
      <Footer />
    </div>
  );
};
